import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import media from "styled-media-query";

const ProjectList = ({
  type,
  tech,
  img,
  techArr,
  name,
  desc,
  bgColor,
  routeName,
}) => {
  return (
    <Container>
      <Hero className="hero" bgColor={bgColor}>
        <HeroIcon>
          <div className="project-type-pill">
            <h6>{type}</h6>
          </div>
          <img src={img} alt="Sam Engine" />
          <div className="project-tech-pill">
            <h6>{tech}</h6>
          </div>
        </HeroIcon>
        <HeroMore>
          <h6>Technology Used</h6>
          <TechTablets>
            {techArr.map((t) => (
              <div className="tech-pill">
                <span>{t}</span>
              </div>
            ))}
          </TechTablets>
          <ReadButton>
            <Link to={`/projects/${routeName}`}>Read More</Link>
          </ReadButton>
        </HeroMore>
      </Hero>
      <Content>
        <h5>{name}</h5>
        <p>{desc}</p>
        <span>Mouse over for more info...</span>
      </Content>
    </Container>
  );
};

export default ProjectList;

const Container = styled.div`
  width: 100%;
  height: 50vh;
  margin: 10vh 0;
  background-color: #343638;
  display: grid;
  grid-template-columns: 2fr;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover .hero {
    width: 100%;
  }
  ${media.lessThan("small")`
    /* screen width is less than 450px (small) */
   display: flex;
   flex-direction:column;
   height: 60vh;
  `}
  ${media.between("small", "780px")`
    /* screen width is between 450px and 768px (small to medium) */
   display: flex;
   flex-direction:column;
   height: 45vh;
  `}
`;

const Hero = styled.div`
  /* background-color: #7c69f1; */

  background-color: ${({ bgColor }) => bgColor};
  /* background: linear-gradient(#de685e, #ee786e); */
  border-radius: 10px;
  width: 550px;
  height: 100%;
  position: absolute;
  transition: width 0.4s;
  overflow: hidden;
  z-index: 2;

  display: grid;
  grid-template-columns: 1fr 2fr;

  h6 {
    font-family: Monda;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
  }

  .project-type-pill,
  .project-tech-pill {
    background: #5547a5;
    border-radius: 18px;

    padding: 5px 8px;
    h6 {
      font-family: Monda;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      /* line-height: 45px; */
    }
  }

  ${media.lessThan("small")`
    /* screen width is less than 450px (small) */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; 
    padding-top: 2vh;
    .project-type-pill,
    .project-tech-pill {
    margin: 1vh 0;
       }
  `}
  ${media.between("small", "780px")`
    /* screen width is between 450px and 768px (small to medium) */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; 
    padding-top: 2vh;
    .project-type-pill,
    .project-tech-pill {
    margin: 1vh 0;
       }
  `}
`;

const HeroIcon = styled.div`
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 250px;

  img {
    transform: scale(1.2);
  }

  ${media.lessThan("small")`
    /* screen width is less than 450px (small) */
    width: 90vw;
    height: 100%;
    padding-left: 10px;
    img {
    transform: scale(1);
  }
  `}
  ${media.between("small", "780px")`
    /* screen width is between 450px and 768px (small to medium) */
    width: 90vw;
    height: 100%;
    padding-left: 00px;
    padding-right: 10vw;
    img {
    transform: scale(1);
  }
  `}
`;
const HeroMore = styled.div`
  height: 100%;
  padding: 0 20px 0 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  ${media.lessThan("small")`
    /* screen width is less than 450px (small) */
    padding: 0 0px 0 00px;
   h6{
     display:none;
   }
  `}
  ${media.between("small", "780px")`
    /* screen width is between 450px and 768px (small to medium) */
    padding: 0 0px 0 00px;
    h6{
     display:none;
   }
  `}
`;
const Content = styled.div`
  padding-left: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  h5 {
    font-family: Monda;
    font-size: 42px;
  }
  p {
    width: 60%;
    font-family: Monda;
    font-size: 24px;
  }
  span {
    justify-content: flex-end;
    padding-left: 250px;
    color: gray;
    /* border-bottom: 1px solid white; */
  }
  ${media.lessThan("small")`
    /* screen width is less than 450px (small) */
   display:none;
  `}
  ${media.between("small", "780px")`
    /* screen width is between 450px and 768px (small to medium) */
    display:none;
  `}
`;

const TechTablets = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  padding: 0 20px;

  .tech-pill {
    border-radius: 18px;
    padding: 5px 15px;
    /* background: #5547a5; */
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(15px);
    text-align: center;
    min-height: 40px;
  }
  span {
    font-family: Monda;
    line-height: 30px;
    font-size: 24px;
  }
  ${media.lessThan("small")`
    /* screen width is less than 450px (small) */
   display:none;
  `}
  ${media.between("small", "780px")`
    /* screen width is between 450px and 768px (small to medium) */
    display:none;
  `}
`;
const ReadButton = styled.button`
  background: #2c2552;
  border-radius: 5px;
  border: none;
  width: 150px;
  height: 42px;
  color: #fff;
  font-size: 24px;
  a {
    color: #fff;
  }
`;

//  <div class="center">
// <div class="card">
//   <div class="additional">
//     <div class="user-card">
//       <div class="level center">
//         Level 13
//       </div>
//       <div class="points center">
//         5,312 Points
//       </div>
//       <svg width="110" height="110" viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="title desc" class="center">
//         <title id="title">Teacher</title>
//         <desc id="desc">Cartoon of a Caucasian woman smiling, and wearing black glasses and a purple shirt with white collar drawn by Alvaro Montoro.</desc>
//         <style>
//           .skin { fill: #eab38f; }
//           .eyes { fill: #1f1f1f; }
//           .hair { fill: #2f1b0d; }
//           .line { fill: none; stroke: #2f1b0d; stroke-width:2px; }
//         </style>
//         <defs>
//           <clipPath id="scene">
//             <circle cx="125" cy="125" r="115"/>
//           </clipPath>
//           <clipPath id="lips">
//             <path d="M 106,132 C 113,127 125,128 125,132 125,128 137,127 144,132 141,142  134,146  125,146  116,146 109,142 106,132 Z" />
//           </clipPath>
//         </defs>
//         <circle cx="125" cy="125" r="120" fill="rgba(0,0,0,0.15)" />
//         <g stroke="none" stroke-width="0" clip-path="url(#scene)">
//           <rect x="0" y="0" width="250" height="250" fill="#b0d2e5" />
//           <g id="head">
//             <path fill="none" stroke="#111111" stroke-width="2" d="M 68,103 83,103.5" />
//             <path class="hair" d="M 67,90 67,169 78,164 89,169 100,164 112,169 125,164 138,169 150,164 161,169 172,164 183,169 183,90 Z" />
//             <circle cx="125" cy="100" r="55" class="skin" />
//             <ellipse cx="102" cy="107" rx="5" ry="5" class="eyes" id="eye-left" />
//             <ellipse cx="148" cy="107" rx="5" ry="5" class="eyes" id="eye-right" />
//             <rect x="119" y="140" width="12" height="40" class="skin" />
//             <path class="line eyebrow" d="M 90,98 C 93,90 103,89 110,94" id="eyebrow-left" />
//             <path class="line eyebrow" d="M 160,98 C 157,90 147,89 140,94" id="eyebrow-right"/>
//             <path stroke="#111111" stroke-width="4" d="M 68,103 83,102.5" />
//             <path stroke="#111111" stroke-width="4" d="M 182,103 167,102.5" />
//             <path stroke="#050505" stroke-width="3" fill="none" d="M 119,102 C 123,99 127,99 131,102" />
//             <path fill="#050505" d="M 92,97 C 85,97 79,98 80,101 81,104 84,104 85,102" />
//             <path fill="#050505" d="M 158,97 C 165,97 171,98 170,101 169,104 166,104 165,102" />
//             <path stroke="#050505" stroke-width="3" fill="rgba(240,240,255,0.4)" d="M 119,102 C 118,111 115,119 98,117 85,115 84,108 84,104 84,97 94,96 105,97 112,98 117,98 119,102 Z" />
//             <path stroke="#050505" stroke-width="3" fill="rgba(240,240,255,0.4)" d="M 131,102 C 132,111 135,119 152,117 165,115 166,108 166,104 166,97 156,96 145,97 138,98 133,98 131,102 Z" />
//             <path class="hair" d="M 60,109 C 59,39 118,40 129,40 139,40 187,43 189,99 135,98 115,67 115,67 115,67 108,90 80,109 86,101 91,92 92,87 85,99 65,108 60,109" />
//             <path id="mouth" fill="#d73e3e" d="M 106,132 C 113,127 125,128 125,132 125,128 137,127 144,132 141,142  134,146  125,146  116,146 109,142 106,132 Z" />
//             <path id="smile" fill="white" d="M125,141 C 140,141 143,132 143,132 143,132 125,133 125,133 125,133 106.5,132 106.5,132 106.5,132 110,141 125,141 Z" clip-path="url(#lips)" />
//           </g>
//           <g id="shirt">
//             <path fill="#8665c2" d="M 132,170 C 146,170 154,200 154,200 154,200 158,250 158,250 158,250 92,250 92,250 92,250 96,200 96,200 96,200 104,170 118,170 118,170 125,172 125,172 125,172 132,170 132,170 Z"/>
//             <path id="arm-left" class="arm" stroke="#8665c2" fill="none" stroke-width="14" d="M 118,178 C 94,179 66,220 65,254" />
//             <path id="arm-right" class="arm" stroke="#8665c2" fill="none" stroke-width="14" d="M 132,178 C 156,179 184,220 185,254" />
//             <path fill="white" d="M 117,166 C 117,166 125,172 125,172 125,182 115,182 109,170 Z" />
//             <path fill="white" d="M 133,166 C 133,166 125,172 125,172 125,182 135,182 141,170 Z" />
//             <circle cx="125" cy="188" r="4" fill="#5a487b" />
//             <circle cx="125" cy="202" r="4" fill="#5a487b" />
//             <circle cx="125" cy="216" r="4" fill="#5a487b" />
//             <circle cx="125" cy="230" r="4" fill="#5a487b" />
//             <circle cx="125" cy="244" r="4" fill="#5a487b" />
//             <path stroke="#daa37f" stroke-width="1" class="skin hand" id="hand-left" d="M 51,270 C 46,263 60,243 63,246 65,247 66,248 61,255 72,243 76,238 79,240 82,243 72,254 69,257 72,254 82,241 86,244 89,247 75,261 73,263 77,258 84,251 86,253 89,256 70,287 59,278" />
//             <path stroke="#daa37f" stroke-width="1" class="skin hand" id="hand-right" d="M 199,270 C 204,263 190,243 187,246 185,247 184,248 189,255 178,243 174,238 171,240 168,243 178,254 181,257 178,254 168,241 164,244 161,247 175,261 177,263 173,258 166,251 164,253 161,256 180,287 191,278"/>
//           </g>
//         </g>
//       </svg>
//     </div>
//     <div class="more-info">
//       <h1>Jane Doe</h1>
//       <div class="coords">
//         <span>Group Name</span>
//         <span>Joined January 2019</span>
//       </div>
//       <div class="coords">
//         <span>Position/Role</span>
//         <span>City, Country</span>
//       </div>
//       <div class="stats">
//         <div>
//           <div class="title">Awards</div>
//           <i class="fa fa-trophy"></i>
//           <div class="value">2</div>
//         </div>
//         <div>
//           <div class="title">Matches</div>
//           <i class="fa fa-gamepad"></i>
//           <div class="value">27</div>
//         </div>
//         <div>
//           <div class="title">Pals</div>
//           <i class="fa fa-group"></i>
//           <div class="value">123</div>
//         </div>
//         <div>
//           <div class="title">Coffee</div>
//           <i class="fa fa-coffee"></i>
//           <div class="value infinity">∞</div>
//         </div>
//       </div>
//     </div>
//   </div>
//   <div class="general">
//     <h1>Jane Doe</h1>
//     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p>
//     <span class="more">Mouse over the card for more info</span>
//   </div>
// </div>
// </div>
